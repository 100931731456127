<template>
  <div class="bg-cream boundaries">
    <VideoModal />
    <section class="container-xl">
      <b-row class="position-relative">
        <b-col cols="12" lg="5" class="img-container">
          <div class="reg-container">
            <div class="reg-text freight-big-pro-book text-brown">
              <p class="h5">
                <span
                  v-scroll-to="{ el: '#register', offset: -100 }"
                  role="button"
                  >Register Now</span
                >
              </p>
            </div>
            <object
              data="/img/register_button.svg"
              type="image/svg+xml"
              class="regbutton zIndex-10"
            ></object>
          </div>
          <img src="/img/building-2.jpg" alt="Suites" class="h-100" />
        </b-col>
        <b-col
          cols="12"
          lg="6"
          class="d-flex align-items-center max-w-550 text-container"
        >
          <div>
            <div
              class="medium-text fade-in-up-element overflow-hidden freight-big-pro-book pb-3 pt-3 pb-md-3"
            >
              Extended Above and Beyond <br />
              the Usual Boundaries
            </div>
            <div class="sm-text lato-regular fade-in-up-element">
              The Hill’s structural elements seamlessly accommodate and give
              context to the tower's design choices, in heritage motifs, in
              colours, neighbouring building spaces and heights — with
              juxtaposing forms and integrating references from the existing
              heritage building at street level and above — while using
              cantilevering details to deliver drama and aesthetics, and
              providing a continuum between the old and the new.
            </div>
            <div class="sm-text lato-regular fade-in-up-element pt-3">
              <span class="font-italic d-block text-brown"
                >THE NORTHERN CANTILEVER</span
              >
              The Hill's northern side features a dramatic cantilevering style,
              bold in character; in a direct response to maintaining the
              harmonious symbiotic relationship with what exists north of the
              heritage building. Making room for valuable outdoor, rooftop
              amenity space on the northern side of the podium.
            </div>
            <div class="sm-text lato-regular fade-in-up-element pt-3">
              <span class="font-italic d-block text-brown"
                >THE EASTERN CANTILEVER
              </span>
              The Hill’s eastern cantilevered tower is arguably one of the
              building’s most dramatic structural moves. Below the heritage
              structure, it appears as if it protrudes three-dimensionally,
              manipulating perception to produce an ever striking and
              breathtaking effect of the storied tower above.
            </div>
            <div
              class="md-text text-brown freight-big-pro-book-italic fade-in-up-element pt-3"
            >
              “The net result is very, very bold and spectacular. Those
              cantilevers are dramatic! You will see them while coming down
              Yonge Street at a significant distance; even before you get to St.
              Clair. And every time I look at them... they speak to me... and
              they say one thing: Look Ma, no hands!”
              <span class="sm-text lato-regular d-block pt-3"
                >Mansoor Kazerouni, Architect, Global Director at Arcadis IBI
                Group.</span
              >
            </div>
            <div class="video-box mt-5">
              <b-row>
                <b-col lg="6">
                  <div class="position-relative" v-b-modal.theHillVideoModal>
                    <img src="/img/the-hill-video-thumb.jpg" alt="The Hill Video" class="w-100 d-block">
                    <div class="max-w-100 play-btn">
                      <img src="/img/play-btn-grey-transparent.png" alt="Play" class="w-100 d-block">
                    </div>
                  </div>
                </b-col>
                <b-col lg="6" class="">
                  <div class="d-lg-flex flex-column justify-content-between h-100">
                    <img src="/img/video-icon.png" alt="" class="w-100 max-w-70">
                    <p class="freight-big-pro-book-italic-bold text-brown h6" style="line-height: 100%">
                      Watch our video and get a sense of Metropia’s vision and the architect’s aspirations for The Hill
                    </p>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import VideoModal from '@/components/VideoModal';

export default {
  components: {
    VideoModal
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";
.boundaries {
  padding: 60px 0px 85px 100px;
  .sm-text {
    font-size: 0.8rem;
  }
  .md-text {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
  .lg-text {
    font-size: 5rem;
    line-height: 4rem;
  }
  .img-container {
    position: relative;
    .reg-container {
    position: relative;
    .regbutton {
      position: absolute;
      top: -8rem;
      left: -2rem;
      max-width: 450px;
      z-index: 9999;
    }
    .reg-text {
      position: absolute;
      top: -5rem;
      left: 0.5rem;
      z-index: 99999;
    }
  }
  
  }
  .text-container {
    // left: 37%;
  }
  @media screen and (max-width: 770px) {
    padding: 0px 45px 100px 45px;
    .img-container {
      position: relative;
    }
    .text-container {
      left: unset;
    }
  }
  @media screen and (max-width: 500px) {
    padding: 16px 25px 40px 25px;
    .reg-container {
     display: none;
    }
    .lg-text {
      font-size: 3.5rem;
      line-height: 3rem;
    }
  }

  .video-box {
    border: 1px solid #968074;
    padding: 8px;

    .play-btn {
      position:absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
